<template>
  <div>
    <div v-if="withUnfollow">
      <button
        v-if="artistFollowed" @click="unfollow" class="button follow-button is-fullwidth is-justify-content-center	"
        :class="{ 'is-fullwidth': fullWidth, 'is-outlined': outlined, 'min-width' : minWidth }"
      >
        <i class="material-icons">undo</i>
        <span>{{ $t('unfollow') }}</span>
      </button>
    </div>
    <button
      v-if="!artistFollowed" class="button is-primary follow-button is-justify-content-center	" @click="follow"
      :class="{ 'is-fullwidth': fullWidth, 'is-outlined': outlined, 'min-width' : minWidth  }"
    >
      <i v-if="withIcon" class="material-icons">cast</i>
      <span>{{ $t('follow') }}</span>
    </button>
    <modal
      v-if="showFollowerModal"
      @close="closeFollowModal"
      :backdropClose="true"
      :defaultHeader="false"
      :defaultFooter="true"
    >
      <div slot="body">
        <div class="is-overlay">
          <section class="scale-hack">
            <div class="content">
              <div class="modal-card animated slideInDown login-modal pt-10 pl-10 pr-10">
                <follower-login @logged="closeFollowModal"></follower-login>
              </div>
            </div>
          </section>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import followerLogin from '~/components/follower-login'
import modal from '~/components/modal'

export default {
  name: 'follow-btn',
  props: ['fullWidth', 'withIcon', 'withUnfollow', 'singlePost', 'outlined', 'minWidth'],
  components: { modal, followerLogin },
  data: () => ({
    showFollowerModal: false
  }),
  computed: {
    ...mapState(['isAuthenticated', 'user', 'artist']),
    artistFollowed() {
      return this.artist.followed === true
    },
    avatarImageId() {
      if (
        !this.artist.profile ||
        typeof this.artist.profile.imgs.avatar === 'string'
      ) {
        return null
      }
      return this.artist.profile.imgs.avatar.name
    }
  },
  methods: {
    ...mapActions(['setFollowArtist', 'refreshFollowerPosts', 'refreshSinglePost']),
    async follow() {
      if (this.isAuthenticated) {
        const data = await this.apiPost(`/api/private/${this.artist.urlAlias}/follow/subscribe`, null, null)
        if (data && data.ok) {
          await this.setFollowArtist(true)
          if (this.singlePost) {
            await this.refreshSinglePost({ urlAlias: this.artist.urlAlias })
          }
          await this.refreshFollowerPosts({ urlAlias: this.artist.urlAlias })
          this.sendFlashMessage()
          this.$emit('followed')
        }
      } else {
        if (!this.singlePost) {
          this.showFollowerModal = true
          return
        }
        this.$emit('showGlobalFollowerModal')
        this.$root.$emit('showGlobalFollowerModal')
      }
    },
    async unfollow() {
      if (this.isAuthenticated) {
        const data = await this.apiPost(`/api/private/${this.artist.urlAlias}/follow/unsubscribe`, null, null)
        if (data && data.ok) {
          await this.setFollowArtist(false)
          await this.refreshFollowerPosts({ urlAlias: this.artist.urlAlias })
        }
      } else {
        const locale = await this.$i18n.locale
        this.$router.push({
          name: `portal-login___${locale}`
        })
      }
    },
    closeFollowModal() {
      this.showFollowerModal = false
    },
    sendFlashMessage() {
      const imageId = this.artist.profile.imgs.avatar.name
      const flashConfig = {
        blockClass: 'flash-gn-profile-success'
      }
      if (this.avatarImageId) {
        flashConfig.icon = `/services/media/image/${this.artist._id}/${imageId}/128/0`
      }
      this.$root.$emit('flash', {
        type: 'toasts.followship.created',
        state: 'success',
        flashConfig,
        payload: {
          artist: this.artist.nickname
        }
      })
    }
  },

  mounted() { }
}
</script>

<style lang="scss">
@import "assets/sass/_variables";
button.follow-button {
  font-weight: 800;
  i {
    font-size: 1.2em;
    padding-right: 0.4em;
  }
  span {
    // padding-left: 0.5em;
    font-weight: 400;
  }
}

.min-width {
  min-width: 100px;
}
</style>
